import mainInit from "./main.js";

import infraLayer from "@frontend/allspark/infra/index_beriberu-lk.js";
import UiKit from "@frontend/allspark/uikit/index_krediska.js";

import Layouts from "@/layouts/index.js";

mainInit({
  infraLayer,
  UiKit,
  swName: "service-worker-beriberu.js",
  Layouts,
});
